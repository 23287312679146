/* eslint-disable react-hooks/rules-of-hooks */
import { useQuery } from '@apollo/client';
import {
  LISTAR_EMPRESAS,
  LISTAR_EMPRESA_ATIVA,
  GET_CARGO_REPRESENTANTE,
  GET_SALDO_EMPRESARIAL,
  USER_LOGGED,
  GET_EQUIPES_CORRETOR,
} from '../gql';

export const capturarEmpresasRepresentante = (auth) => {
  const { data, refetch, loading, error } = useQuery(LISTAR_EMPRESAS, {
    context: {
      headers: {
        Authorization: auth.access_token,
      },
    },
    fetchPolicy: 'no-cache',
    skip: !auth.logged || !auth.access_token,
  });

  return {
    data: data?.empresarial_select_corretoras || [],
    refetch,
    loading,
    error,
  };
};

export const capturarEquipesCorretor = (auth) => {
  const { data, refetch, loading, error } = useQuery(GET_EQUIPES_CORRETOR, {
    context: {
      headers: {
        Authorization: auth.access_token,
      },
    },
    fetchPolicy: 'no-cache',
    skip: !auth.logged || !auth.access_token,
  });

  return {
    data: data?.empresarial_getEquipesCorretor || [],
    refetch,
    loading,
    error,
  };
};

export const capturarDadosEmpresaSelecionada = ({ auth, empresa_ativa }) => {
  const { data, refetch, loading } = useQuery(LISTAR_EMPRESA_ATIVA, {
    variables: {
      id: empresa_ativa,
    },
    context: {
      headers: {
        Authorization: auth,
      },
    },
    fetchPolicy: 'no-cache',
    skip: !auth || !empresa_ativa,
  });

  return {
    data: data?.empresarial_empresa || {},
    refetch,
    loading,
  };
};

export const capturarCargoRepresentante = ({ auth, user_id, empresa_id }) => {
  const { data, refetch, loading } = useQuery(GET_CARGO_REPRESENTANTE, {
    variables: {
      user_id,
      empresa_id,
    },
    context: {
      headers: {
        Authorization: auth,
      },
    },
    fetchPolicy: 'no-cache',
    skip: !auth || !user_id || !empresa_id,
  });

  return { data: data?.empresarial_cargo || {}, refetch, loading };
};
export const capturarCreditoEmpresarial = ({ empresa_id, auth }) => {
  const { data, refetch, loading } = useQuery(GET_SALDO_EMPRESARIAL, {
    variables: {
      empresa_id,
    },
    context: {
      headers: {
        Authorization: auth,
      },
    },
    fetchPolicy: 'no-cache',
    skip: !auth || !empresa_id,
  });

  return { saldo: data?.empresarial_saldo_empresarial || {}, refetch, loading };
};

export const capturarRepresentanteLogado = (auth) => {
  const { loading, error, data, refetch } = useQuery(USER_LOGGED, {
    context: {
      headers: {
        Authorization: auth.access_token,
      },
    },
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    skip: !auth.access_token,
  });

  return {
    error_me: error,
    me: data?.empresarial_me,
    loading_me: loading,
    refetch_me: refetch,
  };
};
